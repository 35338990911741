import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { MainService } from '@app/core/main.service';

@Component({
  selector: 'fidelizacion-reglamento',
  templateUrl: './reglamento.component.html',
  styleUrls: ['./reglamento.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReglamentoComponent implements OnInit {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;

  constructor(public asm: MainService, private cd : ChangeDetectorRef) {}

  texto : string = 'Cargando reglamento...';
  ngOnInit() {
    this.cd.markForCheck();
    this.asm.post("ObtenerTerminosYcondiciones", {}).subscribe((ret: any) => {
      this.texto = ret.texto;
      this.cd.markForCheck();
    });
  }
  
  }