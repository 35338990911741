import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService, ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { MainService } from '@app/core/main.service';

@Component({
  selector: 'fidelizacion-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;

  form = this.fb.group({
    autosave: false,
    password: ['', []],
    email: ['', [Validators.required, Validators.email]],
  });

  enviado: boolean = false;
  deferredPrompt;

  constructor(private fb: FormBuilder,
     public asm: MainService,
      private noti: NotificationService,
      private router : Router,
      private ar : ActivatedRoute,
      private cd: ChangeDetectorRef
  ) { }


  ngOnInit() {

    this.asm.mostrarMensajeEmailValidado = false;
    this.asm.mostrarMensajeEmailNoValidado = false;
    let a =this.ar.snapshot.paramMap.get('emlv');
    if(a == 'emlv'){       this.asm.mostrarMensajeEmailValidado = true;}
    if(a == 'errv'){       this.asm.mostrarMensajeEmailNoValidado = true;}

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/ngsw-worker.js');
      console.log('Registered as service worker');
    }
  }
  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    console.log(e);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
  }

  add_to_home() {
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice.then(choiceResult => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the prompt');
      } else {
        console.log('User dismissed the prompt');
      }
      //this.deferredPrompt = null;
    });
  }




  olvideClave(){
    this.cd.markForCheck();
    this.asm.post("Usuario_ResetearPass", { email : this.form.get('email').value, programa : this.asm.programa}).subscribe((ret) => {
      this.enviado = true;
      this.asm.requesting--;
      this.cd.markForCheck();
      this.noti.warn("Se envió una correo electrónico con instrucciones para recuperar tu contraseña.");

    }, (err) => {
      this.asm.requesting--;
      this.cd.markForCheck();
      if (err.error && err.error.Mensaje)
        this.noti.warn(err.error.Mensaje);

    })
  }

  reenviarActivacion(){
    this.cd.markForCheck();
    this.asm.post("Usuario_ReenviarActivacion", { email : this.form.get('email').value, programa : this.asm.programa}).subscribe((ret) => {
      this.enviado = true;
      this.asm.requesting--;
      this.cd.markForCheck();
      this.noti.warn("Se envió una correo electrónico con instrucciones para recuperar tu contraseña.");

    }, (err) => {
      this.asm.requesting--;
      this.cd.markForCheck();
      if (err.error && err.error.Mensaje)
        this.noti.warn(err.error.Mensaje);

    })
  }

  login() {
    this.cd.markForCheck();
    this.asm.login(this.form.get('email').value, this.form.get('password').value).subscribe((ret) => {

      this.asm.requesting--;
      this.asm.post("Usuario_ObtenerDatos", {}).subscribe((ret)=>{
        this.asm.usuario = ret;
        this.enviado = true;
        this.asm.requesting--;
        this.cd.markForCheck();
        this.router.navigate(['/puntos']);
      })



    }, (err) => {
      this.asm.requesting--;
      this.cd.markForCheck();
      if (err.error && err.error.Mensaje)
        this.noti.warn(err.error.Mensaje);

    })
  }

  hide = true;
  togglePass($event){
	this.hide = !this.hide;
	$event.preventDefault();
  }
}
