import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import {
  trigger, state, style, transition,
  animate, group, query, stagger, keyframes
} from '@angular/animations';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { MainService } from '@app/core/main.service';

@Component({
  selector: 'fidelizacion-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class FAQComponent implements OnInit {
 

  constructor(public asm: MainService,private cd : ChangeDetectorRef) { }


texto : string = 'Cargando faq...';
ngOnInit() {
  this.cd.markForCheck();
  this.asm.post("ObtenerFAQ", {}).subscribe((ret: any) => {
    this.texto = ret.texto;
    this.cd.markForCheck();
  });
}

}