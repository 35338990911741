import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ContactoComponent } from './contacto/contacto.component';
import { LoginComponent } from './login/login.component';
import { ComprasComponent } from './miembros/compras/compras.component';
import { CuponesComponent } from './miembros/cupones/cupones.component';
import { PerfilComponent } from './miembros/perfil/perfil.component';
import { PuntosComponent } from './miembros/puntos/puntos.component';
import { VencimientosComponent } from './miembros/vencimientos/vencimientos.component';
import { RegistrarComponent } from './registrar/registrar.component';
import { ReglamentoComponent } from './reglamento/reglamento.component';

const routes: Routes = [
  {
    path: 'registrar',
    component: RegistrarComponent,
    data: { title: 'fidelizacion.menu.registrar' }
  },
  {
    path: 'registrar/:invi',
    component: RegistrarComponent,
    data: { title: 'fidelizacion.menu.registrar' }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'fidelizacion.menu.login' }
  },
  {
    path: 'login/:emlv',
    component: LoginComponent,
    data: { title: 'fidelizacion.menu.login' }
  },
  {
    path: 'reglamento',
    component: ReglamentoComponent,
    data: { title: 'fidelizacion.menu.reglamento' }
  },
  {
    path: 'contacto',
    component: ContactoComponent,
    data: { title: 'fidelizacion.menu.contacto' }
  }/*,
  {
    path: 'tarjeta',
    component: TarjetaComponent,
    data: { title: 'fidelizacion.menu.tarjeta' }, //canActivate :  [AuthGuardService]
  }*/,
  {
    path: 'puntos',
    component: PuntosComponent,
    data: { title: 'fidelizacion.menu.puntos' },// canActivate :  [AuthGuardService]
  },
  {
    path: 'cupones',
    component: CuponesComponent,
    data: { title: 'fidelizacion.menu.puntos' },// canActivate :  [AuthGuardService]
  },
  {
    path: 'vencimientos',
    component: VencimientosComponent,
    data: { title: 'fidelizacion.menu.vencimientos' },// canActivate :  [AuthGuardService]
  },
  {
    path: 'compras',
    component: ComprasComponent,
    data: { title: 'fidelizacion.menu.compras' }, //canActivate :  [AuthGuardService]
  },
  {
    path: 'perfil',
    component: PerfilComponent,
    data: { title: 'fidelizacion.menu.perfil' }, //canActivate :  [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StaticRoutingModule {}
