import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSort } from '@angular/material';
import { NotificationService, ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { MainService } from '@app/core/main.service';
import { CuponDialogComponent } from './cupon_dialog/cupon_dialog.component';

@Component({
	selector: 'fidelizacion-cupones',
	templateUrl: './cupones.component.html',
	styleUrls: ['./cupones.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CuponesComponent implements OnInit {
	routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;

	@ViewChild(MatSort) sort: MatSort;
	cupones_ofrecidos = [];
	cupones_disponibles = [];
	cupones_anteriores = [];
	cupones = [];
	mostrarPopup: boolean = false;
	constructor(public asm: MainService, private noti: NotificationService, private cd: ChangeDetectorRef, private dialog: MatDialog) {
 /*   this.cupones = [];
    this.cupones[0] = { titulo : 'GANATE UN VIAJE!', descripcion : 'f fasdf asdfasdf asdf asdf asdf ', id : 1, img : '../../../assets/cupones/c10.png'  };
    this.cupones[1] = { titulo : '10% OFF',descripcion : 'fds dfdsff fdfd ', id : 2 , img :'../../../assets/cupones/c15.png'  }; 
    this.cupones[2] = { titulo : 'LLEVA 2 PAGA 1',descripcion : 'asd fasd asd fasdf asdf asd fadsf ', id : 3 , img : '../../../assets/cupones/c20.png'  };
    this.cupones[3] = { titulo : 'REMERAS 100 PUNTOS!',descripcion : 'aaaasd fasd fasdf aa', id : 4 , img : '../../../assets/cupones/c25.png'   };
    this.cupones[4] = { titulo : 'REMERAS 100 PUNTOS!',descripcion : 'aaaasd fasd fasdf aa', id : 4 , img : '../../../assets/cupones/c30.png'  };
    this.cupones[5] = { titulo : 'REMERAS 100 PUNTOS!',descripcion : 'aaaasd fasd fasdf aa', id : 4 , img : '../../../assets/cupones/c35.png'   };
    this.cupones[6] = { titulo : 'REMERAS 100 PUNTOS!',descripcion : 'aaaasd fasd fasdf aa', id : 4 , img : '../../../assets/cupones/c40.png'   };
    this.cupones[7] = { titulo : 'REMERAS 100 PUNTOS!',descripcion : 'aaaasd fasd fasdf aa', id : 4 , img : '../../../assets/cupones/c50.png'   };
  */}

  mensajeCupones = undefined;
  saldo : number = 0;
	ngOnInit() {

		if (!this.asm.usuario) {
			this.asm.requesting++; this.cd.markForCheck();
			this.asm.obtenerUsuariosDatos().subscribe((ret) => {
			  this.mensajeCupones =  this.asm.usuario.mensajeCupones;
			
			  this.asm.requesting--; this.cd.markForCheck();
			})
		   }
		   else {
			this.mensajeCupones =  this.asm.usuario.mensajeCupones;
		}

		this.asm.post("Puntos_Listar", undefined).subscribe((ret) => {
			this.saldo = ret.saldo;
			this.cargarCupones();
		},
			(err) => {
				this.asm.requesting--;
				if (err.error && err.error.Mensaje)
					this.noti.warn(err.error.Mensaje);
			}
		)

		
	}
	cargarCupones() {
		this.asm.post("Cupones_Listar", undefined).subscribe((ret) => {
			this.cupones = ret;
			this.cupones_ofrecidos = [];
			this.cupones_disponibles = [];
			this.cupones_anteriores = [];
			if (ret.length > 0) {
				ret.forEach(cupon => {
					if (cupon.adquirido && !cupon.vencido && !cupon.aplicado && !cupon.autoOfrecido) {
						this.cupones_disponibles.push(cupon);
					}
					if ((cupon.vencido || cupon.aplicado) && !cupon.autoOfrecido) {
						this.cupones_anteriores.push(cupon);
					}
					else if (cupon.adquirible || cupon.autoOfrecido) {
						this.cupones_ofrecidos.push(cupon);
					}//no adquirido ni adquirible, no se muestran
				});
			}
			else {
			}
			this.cd.markForCheck();
		},
			(err) => {
				this.asm.requesting--;
				if (err.error && err.error.Mensaje)
					this.noti.warn(err.error.Mensaje);
			}
		)
	}
	tabSeleccionada = 0;

	varTab2() {
		this.tabSeleccionada = 1;
	}

	PopupDatosCupon(cupon, modo){
		const dialogRef = this.dialog.open(CuponDialogComponent, {
			data: { cupon : cupon, modo : modo, saldoActual : this.saldo}
		   });
		   dialogRef.afterClosed().subscribe(result => {
			if(result  == 'adquirido'){
				this.cargarCupones();
			}
		   });


	}
}


