import * as Modelo from './modelo.entidades';

//export {Accesos} from './Accesos';
export class Accesos extends Modelo._Accesos { }
//export {Clientes} from './Clientes';
export class Clientes extends Modelo._Clientes { }
//export {Depositos} from './Depositos';
export class Depositos extends Modelo._Depositos { }
//export {Descuentos} from './Descuentos';
export class Descuentos extends Modelo._Descuentos { }
//export {ErroresDeProcesos} from './ErroresDeProcesos';
export class ErroresDeProcesos extends Modelo._ErroresDeProcesos { }
//export {FideBeneficios} from './FideBeneficios';
export class FideBeneficios extends Modelo._FideBeneficios { }
//export {FideComunicaciones} from './FideComunicaciones';
export class FideComunicaciones extends Modelo._FideComunicaciones { }
//export {Fidelizaciones} from './Fidelizaciones';
export class Fidelizaciones extends Modelo._Fidelizaciones {
	mensajeCupones : string;
     tokenQR : string;
	usaBarcode : boolean;
     usaQR : boolean;
     usaPin : boolean;
     pin : string;
     clie_CodigoAlfa  : string;
     tokenPDF : string;
       localidad : Localidades;
 }
//export {FideProgramas} from './FideProgramas';
export class FideProgramas extends Modelo._FideProgramas { }
//export {FidePuntosConversion} from './FidePuntosConversion';
export class FidePuntosConversion extends Modelo._FidePuntosConversion { }
//export {FidePuntosCuentaCorriente} from './FidePuntosCuentaCorriente';
export class FidePuntosCuentaCorriente extends Modelo._FidePuntosCuentaCorriente { }
//export {FidePuntosExtras} from './FidePuntosExtras';
export class FidePuntosExtras extends Modelo._FidePuntosExtras { }
//export {FidePuntosTiposMov} from './FidePuntosTiposMov';
export class FidePuntosTiposMov extends Modelo._FidePuntosTiposMov { }
//export {FranjasHorarias} from './FranjasHorarias';
export class FranjasHorarias extends Modelo._FranjasHorarias { }
//export {Localidades} from './Localidades';
export class Localidades extends Modelo._Localidades { }
//export {LogMultiproposito} from './LogMultiproposito';
export class LogMultiproposito extends Modelo._LogMultiproposito { }
//export {Marcas} from './Marcas';
export class Marcas extends Modelo._Marcas { }
//export {PINes} from './PINes';
export class PINes extends Modelo._PINes { }
//export {ProcesosRealizados} from './ProcesosRealizados';
export class ProcesosRealizados extends Modelo._ProcesosRealizados { }
//export {Recursos} from './Recursos';
export class Recursos extends Modelo._Recursos { }
//export {Sucursales} from './Sucursales';
export class Sucursales extends Modelo._Sucursales { }
//export {SysNumeraXAnio} from './SysNumeraXAnio';
export class SysNumeraXAnio extends Modelo._SysNumeraXAnio { }
//export {SystemIDs} from './SystemIDs';
export class SystemIDs extends Modelo._SystemIDs { }
//export {Tickets} from './Tickets';
export class Tickets extends Modelo._Tickets { }
//export {TipoFranjaHoraria} from './TipoFranjaHoraria';
export class TipoFranjaHoraria extends Modelo._TipoFranjaHoraria { }
//export {Usuarios} from './Usuarios';
export class Usuarios extends Modelo._Usuarios { }
//export {Vendedores} from './Vendedores';
export class Vendedores extends Modelo._Vendedores { }
